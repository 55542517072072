import React, { useState } from 'react'
import { Container, FormControl } from 'react-bootstrap'
import cn from 'classnames'

import CopyButton from '../CopyButton'
import Tabs from '../UI/Tabs'

import * as s from './Cash.module.scss'

import DATA from './constants'

const Cash = () => {
  const [activeAccount, setAccount] = useState(0)

  return (
    <Container id="cash" className={s.cash}>
      <div className={s.cash__inner}>
        <h2 className={cn('h1', s.cash__header)}>
          <span>Want to HODL?</span> <br />
          Send cash
        </h2>
        <p className={s.cash__lead}>
          The Government of Ukraine accepts fiat donations as well
        </p>

        <div className={s.cash__content}>
          <p className="h3">USD, GBP and EUR to National Bank of Ukraine</p>

          <Tabs
            labels={DATA.map(({ label }) => label)}
            onClick={setAccount}
            active={activeAccount}
            className={s.tabs}
          >
            <FormControl as="div" className={s.tabs__account} disabled>
              {DATA[activeAccount].account}
              <CopyButton content={DATA[activeAccount].account} />
            </FormControl>
          </Tabs>
        </div>
      </div>
    </Container>
  )
}

export default Cash
