/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Container, Button } from 'react-bootstrap'
import cn from 'classnames'
import gsap from 'gsap'

import useScroll from '~hooks/useScroll'
import Donate from '../Donate'

import partners from './img/partners.svg'

import * as s from './Hero.module.scss'

const Hero = () => {
  const [setData] = useState(null)

  const { scrollY } = useScroll()

  const body =
    typeof document !== 'undefined' ? document.querySelector('body') : null

  const isScrolled =
    scrollY > 900 &&
    scrollY <
      (typeof document !== 'undefined'
        ? body.scrollHeight - window.outerHeight - 100
        : 0)

  const handleButton = () => {
    const scrollTo = document.querySelector('#donate').offsetTop - 90

    gsap.to(window, { scrollTo, ease: 'power2' })
  }

  // useEffect(() => {
  //   fetch('https://api.aid-for-ukraine.io/v1/donation')
  //     .then(async (response) => {
  //       const json = await response.json()

  //       // check for error response
  //       // if (!response.ok) {
  //       //   // get error message from body or default to response statusText
  //       //   const error = (data && data.message) || response.statusText
  //       //   return Promise.reject(error)
  //       // }

  //       setData(json)
  //     })
  //     .catch((error) => {
  //       console.error('There was an error!', error)
  //     })
  // }, [])

  useEffect(() => {
    fetch('/donation.json')
      .then(async (response) => {
        const json = await response.json()

        // check for error response
        if (!response.ok) {
          const error = (json && json.message) || response.statusText
          return Promise.reject(error)
        }

        setData(json)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }, [])

  return (
    <div id="top" className={s.hero}>
      <Container className={s.hero__inner}>
        <div className={s.hero__content}>
          <h1>
            Help Ukraine with <span>crypto</span>, don’t leave us alone with the
            enemy
          </h1>
        </div>
        <Donate />
        <div className={s.powered}>
          <div>
            <strong>Aid For Ukraine</strong> is powered by{' '}
            <a href="https://everstake.one" target="_blank" rel="noreferrer">
              Everstake
            </a>{' '}
            and{' '}
            <a href="https://kuna.io" target="_blank" rel="noreferrer">
              Kuna
            </a>
          </div>
          <img src={partners} alt="Everstake ⨯ Kuna" />
        </div>
      </Container>
      <Button
        className={cn(s.button, { [s.show]: isScrolled })}
        onClick={handleButton}
      >
        Help Ukraine
      </Button>
    </div>
  )
}

export default Hero
