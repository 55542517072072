const MENU = [
  {
    name: 'Why donate',
    link: '#why',
  },
  {
    name: `Final Report`,
    link: '#report',
  },
  {
    name: 'Send cash',
    link: '#cash',
  },
  {
    name: 'FAQ',
    link: '#faq',
  },
  {
    name: 'Donate NFT',
    link: 'https://aid-for-ukraine.io/nft',
    icon: 'external',
  },
]

export default MENU
