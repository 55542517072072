import React, { useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container } from 'react-bootstrap'

import * as s from './Help.module.scss'

const Help = () => {
  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "photos" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 400, height: 300, placeholder: NONE)
              blurHash(componentX: 3, componentY: 4, width: 50) {
                base64Image
                hash
              }
            }
          }
        }
      }
    }
  `)

  const photos = useRef()

  useEffect(() => {
    photos.current.scrollLeft =
      window.outerWidth < 1440
        ? (photos.current.scrollWidth - window.outerWidth) / 2
        : photos.current.scrollWidth
  }, [])

  return (
    <section id="why" className={s.help}>
      <Container>
        <div className={s.help__inner}>
          <h2 className="h1">
            <span>Ukraine</span> needs your help
          </h2>
          <div className={s.help__content}>
            <p>
              Ukraine is in the middle of a humanitarian disaster. Hundreds of
              children have died. Thousands of adults have died. The people will
              continue their fight for freedom, but they need more ammunition
              and necessities.
            </p>
            <p>
              Help Ukraine with BTC, ETH, USDT, SOL, DOT, and other
              cryptocurrencies. The funds will be used for the support of
              humanitarian aid programs and the Armed Forces of Ukraine.
            </p>
          </div>
        </div>
        <div className={s.photos} ref={photos}>
          <div className={s.photos__inner}>
            {allFile.edges.map(({ node }) => (
              <GatsbyImage
                key={node.name}
                image={{
                  ...node.childImageSharp.gatsbyImageData,
                  placeholder: {
                    fallback: node.childImageSharp.blurHash.base64Image,
                  },
                }}
                alt={node.name}
                className={s.photo}
              />
            ))}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Help
