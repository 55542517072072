import React, { useState } from 'react'
import Icon from '../Icon'

import * as s from './CopyButton.module.scss'

const CopyButton = ({ content }) => {
  const [copied, setCopied] = useState(null)

  const handleCopy = (text) => {
    if (!copied) {
      navigator.clipboard.writeText(text).then(
        () => {
          setCopied(true)

          setTimeout(() => {
            setCopied(false)
          }, 3000)
        },
        () => {
          alert('Error occured')
        }
      )
    }
  }

  return (
    <button
      type="button"
      onClick={() => handleCopy(content)}
      className={s.button}
    >
      <Icon name={copied ? 'check' : 'copy'} />
    </button>
  )
}

export default CopyButton
