/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Container } from 'react-bootstrap'
import cn from 'classnames'

import * as s from './FAQ.module.scss'

const FAQ = () => {
  return (
    <section id="faq" className={s.faq}>
      <Container>
        <div className={s.faq__inner}>
          <h2 className={cn('h1', s.faq__header)}>
            <span>Got questions?</span> <br />
            We have answers!
          </h2>
          <dl className={s.faq__items}>
            <dt>
              <h3 className="h2">
                Why did we make this site and how does it work?
              </h3>
            </dt>
            <dd>
              <p>
                The crypto community does not want to stand aside and watch
                Ukrainians suffer from the unprovoked aggression by the Russian
                Federation and the subsequent humanitarian disaster unseen in
                Europe since the Balkan war.
              </p>
              <p>
                Everstake and Kuna are Ukraine-based companies. We wanted to
                give the crypto community a viable option to efficiently stand
                with Ukraine. Having seen our goals align and more and more
                blockchain companies wishing to get on board, we merged our
                initiatives under the common name of{' '}
                <strong>Aid For Ukraine</strong>.
              </p>
              {/* <p>
                <strong>Aid For Ukraine</strong> is cooperating with the
                cryptocurrency exchange FTX which converts crypto funds received
                into fiat and sends the donations to the National Bank of
                Ukraine. This marks the first-ever instance of a cryptocurrency
                exchange directly cooperating with a public financial entity to
                provide a conduit for crypto donations.
              </p> */}
            </dd>
            <dt>
              <h3 className="h2">Is it legit?</h3>
            </dt>
            <dd>
              <p>
                Yes! The initiative is powered by{' '}
                <a
                  href="https://everstake.one"
                  target="_blank"
                  rel="noreferrer"
                >
                  Everstake
                </a>{' '}
                and{' '}
                <a href="https://kuna.io/en/" target="_blank" rel="noreferrer">
                  Kuna
                </a>
                .{' '}
                <a
                  href="https://everstake.one"
                  target="_blank"
                  rel="noreferrer"
                >
                  Everstake
                </a>{' '}
                has verified the initiative on his Twitter, and so did the
                Minister of Digital Transformation of Ukraine{' '}
                <a
                  href="https://twitter.com/FedorovMykhailo/status/1503470362641772558"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mykhailo Fedorov
                </a>
                .
              </p>
            </dd>
            <dt>
              <h3 className="h2">How will the funds be used?</h3>
            </dt>
            <dd>
              <p>
                All funds raised through this effort go directly towards aiding
                Ukrainians. The donations are sent directly for procurement
                through volunteer organizations and various ministries, and to
                the dedicated account with the National Bank of Ukraine. Here is
                the{' '}
                <a
                  href="https://twitter.com/_AidForUkraine/status/1516467284046401544"
                  target="_blank"
                  rel="noreferrer"
                >
                  first report
                </a>
                .
              </p>
            </dd>
          </dl>
        </div>
      </Container>
    </section>
  )
}

export default FAQ
