import React from 'react'

import cn from 'classnames'

import * as s from './Tabs.module.scss'

const Tabs = ({ labels, onClick, active, className, children }) => (
  <div className={className}>
    <ul className={s.tabs__buttons}>
      {labels.map((label, i) => (
        <li key={label}>
          <button
            type="button"
            className={cn(s.tabs__button, {
              [s.active]: i === active,
            })}
            onClick={() => onClick(i)}
          >
            {label}
          </button>
        </li>
      ))}
    </ul>
    {children}
  </div>
)

export default Tabs
