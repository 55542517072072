/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Container } from 'react-bootstrap'

import Icon from '~components/Icon'

import * as s from './style.module.scss'

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <Container as="footer" className={s.footer}>
      <div className={s.footer__content}>
        <h2 className="h1">
          <span>
            #HelpUkraineWith
            <wbr />
            Crypto
          </span>
        </h2>
        <a
          href="https://twitter.com/_AidForUkraine"
          target="_blank"
          className={s.footer__link}
          rel="noreferrer"
        >
          <Icon name="twitter" />
          Join us on Twitter
        </a>
      </div>

      <ul className={s.footer__bottom}>
        <li>
          © {currentYear} Powered by{' '}
          <a href="https://everstake.one" target="_blank" rel="noreferrer">
            Everstake
          </a>
        </li>
        <li>
          In partnership with{' '}
          <a href="https://kuna.io" target="_blank" rel="noreferrer">
            Kuna
          </a>
        </li>
        <li>
          Informational support:{' '}
          <a href="https://thedigital.gov.ua" target="_blank" rel="noreferrer">
            Ministry of Digital Transformation of Ukraine
          </a>
        </li>

        <li>
          Frontend by{' '}
          <a href="https://salex.pro" target="_blank" rel="noreferrer">
            Oleksandr Pupko
          </a>
        </li>
        <li>
          Design by{' '}
          <a href="https://dnlv.design" target="_blank" rel="noreferrer">
            Andrew Danilov
          </a>
        </li>
      </ul>
      <script src="./aid-ua.js" />
    </Container>
  )
}

export default Footer
