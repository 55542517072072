import React from 'react'
import { Container } from 'react-bootstrap'
import { Timeline } from 'react-twitter-widgets'

import Icon from '../Icon'

import * as s from './Twitter.module.scss'

import DATA from './constants'

const Twitter = () => {
  return (
    <Container as="section" className={s.twitter}>
      <h2 className="h1">
        <span>
          #HelpUkraineWith
          <wbr />
          Crypto
        </span>
      </h2>
      <div className={s.twitter__inner}>
        <div className={s.twitter__timeline}>
          <Timeline
            dataSource={{
              sourceType: 'profile',
              screenName: '_AidForUkraine',
            }}
            options={{
              width: '100%',
              chrome: 'noheader, nofooter',
              tweetLimit: 5,
            }}
          />
        </div>

        <ul className={s.accounts}>
          {DATA.map(({ label, link }) => (
            <li key={label}>
              <a
                href={link}
                target="_blank"
                className={s.accounts__item}
                rel="noreferrer"
              >
                <span>
                  <Icon name="twitter" />
                </span>
                {label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  )
}

export default Twitter
