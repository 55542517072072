import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container } from 'react-bootstrap'
import { chunk } from 'lodash'

import * as s from './Quotes.module.scss'

const Quotes = () => {
  const { allQuotesJson } = useStaticQuery(graphql`
    {
      allQuotesJson {
        edges {
          node {
            quote
            position
            name
            photo {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 64, placeholder: NONE)
              }
            }
          }
        }
      }
    }
  `)

  const chunks = chunk(allQuotesJson?.edges, 2)

  return (
    <Container as="section" className={s.quotes}>
      <div className={s.quotes__items}>
        {chunks.map((ch, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={s.quotes__column} key={`ch${i}`}>
            {ch.map(({ node: { quote, name, position, photo } }) => (
              <figure className={s.block} key={name}>
                <blockquote className={s.block__quote}>
                  <p>{quote}</p>
                </blockquote>
                <figcaption className={s.block__content}>
                  <div className={s.block__author}>
                    <p className="h3">{name}</p>
                    <cite>{position}</cite>
                  </div>
                  <GatsbyImage
                    className={s.block__photo}
                    image={photo?.childImageSharp?.gatsbyImageData}
                    alt={name}
                  />
                </figcaption>
              </figure>
            ))}
          </div>
        ))}
      </div>
    </Container>
  )
}

export default Quotes
