// extracted by mini-css-extract-plugin
export var amount = "_6n_bK1";
export var bottom = "_6n_bt1";
export var custom = "_6n_bP1";
export var defs = "_6n_bS1";
export var donate = "_6n_bs1";
export var donate__back = "_6n_bD1";
export var donate__header = "_6n_bx1";
export var donate__inner = "_6n_bw1";
export var donate__lead = "_6n_bC1";
export var hide = "_6n_bG1";
export var list = "_6n_bJ1";
export var list__item = "_6n_bL1";
export var list__label = "_6n_bN1";
export var only_copy = "_6n_bB1";
export var s_amount = "_6n_by1";
export var s_custom = "_6n_bz1";
export var s_wallet = "_6n_bv1";
export var scroll = "_6n_51";
export var shadow = "_6n_bF1";
export var tokens = "_6n_bM1";
export var wallet = "_6n_bH1";
export var wallets = "_6n_bQ1";
export var wallets__item = "_6n_bR1";