import React from 'react'
import PropTypes from 'prop-types'
// import { useLocation } from '@gatsbyjs/reach-router'
// import { withPrefix } from 'gatsby'
import { Nav } from 'react-bootstrap'
import cn from 'classnames'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

import Icon from '../Icon'

import MENU from './constants'

const Menu = ({ variant, closeMenu }) => {
  // const location = useLocation()
  // const isHomepage = location.pathname === withPrefix('/')

  gsap.registerPlugin(ScrollToPlugin)

  const handleScroll = (e, link) => {
    e.preventDefault()
    closeMenu()
    gsap.to(window, { scrollTo: link, ease: 'power2' })
  }

  return (
    <Nav className={cn({ [`nav--${variant}`]: variant })} as="ul">
      {MENU.map(({ name, link, icon }) => (
        <Nav.Item as="li" key={name}>
          {link.includes('#') ? (
            <Nav.Link href={link} onClick={(e) => handleScroll(e, link)}>
              {name}
            </Nav.Link>
          ) : (
            <Nav.Link
              href={link}
              target="_blank"
              className={icon && 'nav-link--icon'}
            >
              {name}
              {icon && <Icon name={icon} />}
            </Nav.Link>
          )}
        </Nav.Item>
      ))}
    </Nav>
  )
}

Menu.defaultProps = {
  variant: '',
}

Menu.propTypes = {
  variant: PropTypes.string,
}

export default Menu
