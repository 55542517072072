import React, { useState, useRef } from 'react'
import { Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import cn from 'classnames'

import useScroll from '~hooks/useScroll'
import useClickOutside from '~hooks/useClickOutside'

import Menu from '~components/Menu'
import logo from './img/logo.svg'

import * as s from './style.module.scss'

const Header = ({ siteTitle }) => {
  const { scrollY } = useScroll()

  const isScrolled = scrollY > 40

  const handleScroll = (e) => {
    e.preventDefault()
    gsap.to(window, { scrollTo: 0, ease: 'power2' })
  }

  const header = useRef()

  const [isMenuOpen, setMenuOpen] = useState(null)

  const handleMenu = () => setMenuOpen((prev) => !prev)

  useClickOutside([header], () => setMenuOpen(false))

  return (
    <header
      ref={header}
      className={cn(s.header, { [s.scroll]: isScrolled, [s.open]: isMenuOpen })}
    >
      <Container className={s.header__inner}>
        <a
          href="#top"
          onClick={(e) => handleScroll(e)}
          className={s.header__logo}
        >
          <img src={logo} alt={siteTitle} />
        </a>
        <Menu closeMenu={() => setMenuOpen(false)} />
        <button
          type="button"
          data-toggle="dropdown"
          aria-expanded={isMenuOpen}
          onClick={handleMenu}
          className={s.hamb}
        >
          <svg className={s.hamb__icon} viewBox="0 0 100 100">
            <path
              className={cn(s.hamb__line, s.hamb__line_top)}
              d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
            />
            <path
              className={cn(s.hamb__line, s.hamb__line_middle)}
              d="m 30,50 h 40"
            />
            <path
              className={cn(s.hamb__line, s.hamb__line_bottom)}
              d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
            />
          </svg>
        </button>
      </Container>
    </header>
  )
}

Header.defaultProps = {
  siteTitle: '',
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
