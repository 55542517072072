// extracted by mini-css-extract-plugin
export var breakdown = "_6h_F1";
export var breakdown__inner = "_6h_G1";
export var grid = "_6h_J1";
export var item = "_6h_K1";
export var item__cat = "_6h_M1";
export var item__cats = "_6h_L1";
export var item__icon = "_6h_P1";
export var item__total = "_6h_N1";
export var tabs = "_6h_H1";
export var tall = "_6h_R1";
export var total = "_6h_S1";
export var wide = "_6h_Q1";