const DATA = [
  {
    label: 'List of reporters on the ground',
    link: 'https://twitter.com/i/lists/1494327296383021062',
  },
  {
    label: 'Ministry of Foreign Affairs of Ukraine',
    link: 'https://twitter.com/mfa_ukraine',
  },
  {
    label: 'Official Twitter account of Ukraine',
    link: 'https://twitter.com/Ukraine',
  },
]

export default DATA
