const DATA = [
  {
    label: 'USD',
    account: `BENEFICIARY: National Bank of Ukraine
BENEFICIARY BIC: NBUA UA UX
BENEFICIARY ADDRESS: 9 Instytutska St, Kyiv, 01601, Ukraine
ACCOUNT NUMBER: 400807238
BENEFICIARY BANK NAME: JP MORGAN CHASE BANK, New York
BENEFICIARY BANK BIC: CHASUS33
ABA 0210 0002 1
BENEFICIARY BANK ADDRESS: 383 Madison Avenue, New York, NY 10017, USA
PURPOSE OF PAYMENT: for crediting account 47330992708`,
  },
  {
    label: 'EUR',
    account: `BENEFICIARY: National Bank of Ukraine
BENEFICIARY BIC: NBUAUAUXXXX
IBAN DE05504000005040040066
PURPOSE OF PAYMENT: for crediting account 47330992708
BENEFICIARY BANK NAME: DEUTSCHE  BUNDESBANK, Frankfurt
BENEFICIARY BANK BIC: MARKDEFF
BENEFICIARY BANK ADDRESS: Wilhelm-Epstein-Strasse 14, 60431 Frankfurt Am Main, Germany`,
  },
  {
    label: 'GBP',
    account: `BENEFICIARY/RECIPIENT NAME: National Bank of Ukraine
ACCOUNT NUMBER: GB52CHAS60924280033041
BENEFICIARY ADDRESS: 9 Instytutska St, Kyiv, 01601, Ukraine
BENEFICIARY BANK NAME: JP MORGAN CHASE BANK NA, London
BENEFICIARY BANK BIC: CHASGB2L
SORT CODE: 60-92-42
BENEFICIARY BANK ADDRESS: 125 London Wall, London EC2Y 5AJ, UK
REFERENCE FOR CREDITING ACCOUNT: 47330992708`,
  },
  {
    label: 'AUD',
    account: `BENEFICIARY: National Bank of Ukraine
BENEFICIARY BIC: NBUA UA UX
BENEFICIARY ADDRESS: 9 Instytutska St, Kyiv, 01601, Ukraine
ACCOUNT NUMBER: 817532
BSB code 092-002
BENEFICIARY BANK NAME: RESERVE  BANK OF AUSTRALIA, Sydney
BENEFICIARY BANK BIC: RSBKAU2S
BENEFICIARY BANK ADDRESS: GPO Box 3947, Sydney NSW 2000, Australia
PURPOSE OF PAYMENT: for crediting account 47330992708`,
  },
  {
    label: 'CAD',
    account: `BENEFICIARY: National Bank of Ukraine
BENEFICIARY BIC: NBUA UA UX
BENEFICIARY ADDRESS: 9 Instytutska St, Kyiv, 01601, Ukraine
ACCOUNT NUMBER: 3144-1044-166
BENEFICIARY BANK NAME: BANK OF MONTREAL, Toronto
BENEFICIARY BANK BIC: BOFMCAM2
BENEFICIARY BANK ADDRESS: 100 King Street West, 24th Floor, Toronto, Ontario, M5X 1A9, Canada
PURPOSE OF PAYMENT: for crediting account 47330992708`,
  },
]

export default DATA
