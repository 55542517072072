import React from 'react'

import Layout from '../components/Layout'
import S from '../components/seo'
import Hero from '../components/Hero'
import Quotes from '../components/Quotes'
import Help from '../components/Help'
import Breakdown from '../components/Breakdown'
import Cash from '../components/Cash'
import Twitter from '../components/Twitter'
import FAQ from '../components/FAQ'

const IndexPage = () => (
  <Layout>
    <S />
    <Hero />
    <Quotes />
    <Help />
    <Breakdown />
    <Cash />
    <Twitter />
    <FAQ />
  </Layout>
)

export default IndexPage
