import React, { useState, useEffect } from 'react'
import cn from 'classnames'

import useScroll from '~hooks/useScroll'

import * as s from './Donate.module.scss'

const Donate = () => {
  const STEPS = ['init', 'amount', 'custom', 'wallet']

  const { scrollY } = useScroll()

  const isScrolled = scrollY > 40

  const [setShadow] = useState(null)

  const [step, setStep] = useState(STEPS[0])
  const [selectedToken] = useState(null)

  const handleBack = (currStep) => {
    if (currStep === STEPS[3]) {
      setStep(STEPS[1])
    } else if (step !== STEPS[0]) {
      const currIndex = STEPS.findIndex((el) => el === currStep)
      setStep(STEPS[currIndex - 1])
    }
    setShadow(false)
  }

  const SCROLL_OFFSET = 20

  const handleScroll = (e) => {
    const rect = e.target

    if (
      rect.scrollHeight - SCROLL_OFFSET >=
      rect.offsetHeight + rect.scrollTop
    ) {
      setShadow(false)
    } else {
      setShadow(true)
    }
  }

  const handleEsc = (e) => {
    if ((e.code === 'Escape' || e.key === 'Escape') && step !== STEPS[0]) {
      handleBack(step)
    }
  }

  const [btm, setBtm] = useState(null)

  const handleWindowScroll = () => {
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    )

    const widget = document.querySelector('#donate')

    const isBottom =
      scrollHeight - window.pageYOffset - 262 - widget.offsetHeight - 40 < 0

    setBtm(isBottom && !btm)
  }

  useEffect(() => {
    window.addEventListener('keydown', (e) => handleEsc(e, step))

    window.addEventListener('scroll', handleWindowScroll)

    return () => {
      window.removeEventListener('keydown', (e) => handleEsc(e, step))
      window.removeEventListener('scroll', handleWindowScroll)
    }
  }, [step])

  return (
    <div
      id="donate"
      className={cn(s.donate, [s[`s_${step}`]], {
        [s.scroll]: isScrolled,
        [s.only_copy]: !selectedToken?.amounts && step === STEPS[1],
        [s.bottom]: btm,
      })}
    >
      <div className={s.donate__inner} onScroll={handleScroll}>
        <p>
          All crypto donations have been allocated to meet the needs of the
          Armed Forces of Ukraine, actively engaged in repelling the Russian
          occupants.
        </p>
        <p>
          The charity initiative, Aid For Ukraine, has successfully fulfilled
          its mission by swiftly raising funds during the initial and most
          challenging days of the full-scale invasion.
        </p>
        <p>We are now providing a report on our collective efforts.</p>
      </div>
    </div>
  )
}

export default Donate
